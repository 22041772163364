import React, { useEffect, useRef, useState } from 'react';
import { fetchRapportFinancier, rapportfinancierSelector, fetchBandeControle, setFinBandeControle } from './rapportfinancierSlice';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button';
import styles from './RapportFinancier.module.css';
import { Rapport } from '../rapport/Rapport';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container'
import format from "date-fns/format";
import fr from 'date-fns/locale/fr';
import Spinner from 'react-bootstrap/Spinner';

import {
  Redirect,
  useHistory,
  useParams,
  withRouter
} from "react-router-dom";

export const RapportFinancier = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const history = useHistory();
  const dispatch = useDispatch()
  const service = useSelector(state => state.service.service)
  const cumul = useSelector(state => state.cumul.cumul)
  const rapports = useSelector(state => state.rapports)
  const rapport = useSelector(state => state.rapport.rapport)
  const { rapportfinancier, loading, hasErrors, loaded, donnee, hasMore, finBandeControle } = useSelector(rapportfinancierSelector)
  const [hasLess, sethasLess] = useState(true);
  const [pause, setPause] = useState(false);
  let timer;

  const [isSmallScreen655, setIsSmallScreen655] = useState(window.innerWidth <= 655);
  const [isSmallScreen1024, setIsSmallScreen1024] = useState(window.innerWidth <= 1024 && window.innerWidth > 655);

  useEffect(() => {
    dispatch(fetchRapportFinancier(service[0]))
  }, [dispatch, service])

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen1024(window.innerWidth <= 1024 && window.innerWidth > 655);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen1024]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen655(window.innerWidth <= 655);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen655]);

  
  console.log("valeur de rapport_id: ", rapport.rapport_id);

  useEffect(() => {
    if (rapport.rapport_id == 5) {
      if (rapportfinancier.sections.length >= rapportfinancier.taille) {
        dispatch(setFinBandeControle())
        clearInterval(timer);
        return;
      } else {
        if (hasLess) {
          timer = setInterval(() => {
            dispatch(fetchBandeControle(service[0]))
          }, 5000);  
        }
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [hasLess, rapportfinancier, dispatch, service])

  const fonctionPause = () => {
    clearInterval(timer);
    sethasLess(false)
    setPause(true)
  }

  const fonctionReprise = () => {
    sethasLess(true)
    setPause(false)
  }

  // Extraire les titres des tableaux et les organiser dans un tableau 2D
//  const extractTitles = (rapportfinancier) => {
//    const titles = [];
//  
//    // Parcourir les sections du rapport
//    if (!rapportfinancier.sections || !Array.isArray(rapportfinancier.sections)) {
//      console.error('Erreur : rapportfinancier.sections est invalide ou n’est pas un tableau');
//      return titles;
//    }
//  
//    rapportfinancier.sections.forEach((section, sectionIndex) => {
//      if (!section.tableaux || !Array.isArray(section.tableaux)) {
//        console.error(`Erreur : section.tableaux est invalide ou n’est pas un tableau dans la section ${sectionIndex}`);
//        return;
//      }
//  
//      const sectionTitles = [];
//  
//      // Parcourir les tableaux dans chaque section
//      section.tableaux.forEach((tableau, tableauIndex) => {
//        if (!tableau.lignes || !Array.isArray(tableau.lignes)) {
//          console.error(`Erreur : tableau.lignes est invalide ou n’est pas un tableau dans le tableau ${tableauIndex} de la section ${sectionIndex}`);
//          return;
//        }
//  
//        const tableauTitles = [];
//  
//        // Parcourir les lignes de chaque tableau pour identifier les titres
//        tableau.lignes.forEach((ligne, ligneIndex) => {
//          if (ligne.ligne_type === 'titre') {
//            tableauTitles.push({
//              index: ligneIndex,          // Index de la ligne du titre
//              titre: ligne.ligne_donnee,  // Les données du titre (tableau des colonnes)
//              tableauNom: tableau.tableau_nom,  // Nom du tableau
//              sectionNom: section.section_nom   // Nom de la section
//            });
//          }
//        });
//  
//        // Si on a trouvé des titres, les ajouter à la section
//        if (tableauTitles.length > 0) {
//          sectionTitles.push(tableauTitles);
//        }
//      });
//  
//      // Ajouter les titres trouvés pour la section à notre tableau général
//      if (sectionTitles.length > 0) {
//        titles.push({
//          section: section.section_nom, // Nom de la section
//          tableaux: sectionTitles       // Liste des titres trouvés dans cette section
//        });
//      }
//    });
//  
//    return titles;
//  };
  
// Appel de la fonction après le chargement des données
//const titlesTable = extractTitles(rapportfinancier);

//console.log('Tableau des titres :', titlesTable);
  
// Header pour les Totaux
const renderHeaderTotaux = (ligne, isSmallScreen655) => {
  if (ligne.ligne_type === 'titre') {
    if (rapport.rapport_id == 4){
      if (isSmallScreen655) {
        return (
          <>
            <tr className={styles.rapport4_table}>
              <th className={styles.rapport4_table} colSpan={5}>{ligne.ligne_donnee[0]}</th> {/* Groupe */}
            </tr>
            <tr className={styles.rapport4_table}>
              <th className={styles.rapport4_table}>
                <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
              </th>
              <th className={styles.rapport4_table}>
                <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
              </th>
            </tr>
          </>
        );
      }else {
        return (
          <tr className={styles.rapport4_table}>
            {ligne.ligne_donnee.map((donnee, index) => (
              <th className={styles.rapport4_table} key={index}>
                {donnee}
              </th>
            ))}
          </tr>
        );
      }
    }else{
      if(ligne.ligne_type == 'titre') {
        return (ligne.ligne_donnee.map((donnee) =>
          <th>{donnee}</th>
        ))
      }  
    }
  }  
};

// Body pour les Totaux
const renderBodyTotaux = (ligne, isSmallScreen655, rowIndex) => {
  if (ligne.ligne_type === 'valeur') {
    if (rapport.rapport_id == 4){
      const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`; // Classe spéciale pour la première cellule
      if (isSmallScreen655) {
        return (
          <>
            <tr className={styles.rapport4_table}>
              <td className={firstCellClass} colSpan={5}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
            </tr>
            <tr className={styles.rapport4_table}>
              <td className={styles.rapport4_table}>
                <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[2]}</div> {/* H.T./H.S. */}
              </td>
              <td className={styles.rapport4_table}>
                <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
              </td>
            </tr>
          </>
        );
      } else {
        return (
          <tr className={styles.rapport4_table}>
            <td className={firstCellClass}>{ligne.ligne_donnee[0]}</td> {/* Groupe */}
            {ligne.ligne_donnee.slice(1).map((donnee, index) => (
              <td className={styles.rapport4_table} key={index}>
                {donnee} {/* T.T.C., H.T./H.S., T.V.A., Service */}
              </td>
            ))}
          </tr>
        );
      }
    }else if (rapportfinancier.rapport_id == 2 || rapportfinancier.rapport_id == 3) {

      if(ligne.ligne_type == 'valeur') {

        return ligne.ligne_donnee.map(function(donnee, index) {
          
          if(donnee == 'Total annulations' || donnee == 'Total offerts') {
            return <td id={index} colSpan="4" className={styles.total}>{donnee}</td>
          } else {
            return <td id={index}>{donnee}</td>
          }
        })
      }
    } else {
      if(ligne.ligne_type == 'valeur') {
        return (ligne.ligne_donnee.map((donnee, index) =>
          <td id={index}>{donnee}</td>
        ))
      }
    }
  }
};


// Header pour Famille
const renderHeaderFamille = (ligne, isSmallScreen655, isSmallScreen1024) => {
  const screenSize = isSmallScreen655 
  ? "isSmallScreen655" 
  : isSmallScreen1024 
  ? "isSmallScreen1024" 
  : null;

  if (ligne.ligne_type === 'titre') {
    if (rapport.rapport_id == 4){
        const columnCount = ligne.ligne_donnee.length - 1; // Compte les colonnes à afficher (sans le Code)
        if (rapports.cumul) {
                    // Switch case pour les différentes tailles d'écran
          switch (screenSize) {
              case "isSmallScreen655":
                // Cas où la fenêtre est <= 655px
                const filteredDonnees655 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
                return (
                  <>
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>{filteredDonnees655[0]}</th> {/* Code */}
                      <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees655[1]}</th> {/* Désignation avec colSpan sous 655px */}
                    </tr>
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                      </th>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                      </th>
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                    </tr>
                  </>
                );
              case "isSmallScreen1024":
                // Cas où la fenêtre est entre 655px et 1024px
                const filteredDonnees1024 = ligne.ligne_donnee.filter((donnee) => donnee.toLowerCase() !== 'ratios'); // Enlever "Ratios"
                return (
                  <>
                    <tr className={styles.rapport4_table}>
                      <th className={styles.rapport4_table}>{filteredDonnees1024[0]}</th> {/* Code */}
                      <th className={styles.rapport4_table}>{filteredDonnees1024[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                      {filteredDonnees1024.slice(2).map((donnee, index) => (
                        <th className={styles.rapport4_table} key={index}>
                          {donnee} {/* QTE Vendus, T.T.C., etc. */}
                        </th>
                      ))}
                    </tr>
                  </>
                );
              
              default:
                // Cas par défaut (logique actuelle de l'else)
                return (
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[0]}</th> {/* Code */}
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
                    {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                      <th className={styles.rapport4_table} key={index}>
                        {donnee} {/* QTE Vendus, T.T.C., etc. */}
                      </th>
                    ))}
                  </tr>
                );
          }
        } else {
        const filteredDonnees = ligne.ligne_donnee.filter(donnee => {
          if (typeof donnee === 'string') {
            return donnee.trim().toLowerCase() !== 'ratios';
          }
          return true;
        });

        if (isSmallScreen655) {
          return (
            <>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
                <th className={styles.rapport4_table} colSpan={2}>{filteredDonnees[1]}</th> {/* Désignation avec colSpan sous 655px */}
              </tr>
              <tr className={styles.rapport4_table}>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                </th>
                <th className={styles.rapport4_table}>
                  <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                  <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                </th>
                <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
              </tr>
            </>
          );
        } else {
          return (
            <tr className={styles.rapport4_table}>
              <th className={styles.rapport4_table}>{filteredDonnees[0]}</th> {/* Code */}
              <th className={styles.rapport4_table}>{filteredDonnees[1]}</th> {/* Désignation sans colSpan pour les écrans > 655px */}
              {filteredDonnees.slice(2).map((donnee, index) => (
                <th className={styles.rapport4_table} key={index}>
                  {donnee} {/* QTE Vendus, T.T.C., etc. */}
                </th>
              ))}
            </tr>
          );
        }
      }
    }else{
        return (ligne.ligne_donnee.map((donnee) =>
          <th>{donnee}</th>
        )) 
    }
  }
};

// Body pour Famille
const renderBodyFamille = (ligne, isSmallScreen655, isSmallScreen1024) => {
  if(rapport.rapport_id == 4){
      const screenSize = isSmallScreen655 
        ? "isSmallScreen655" 
        : isSmallScreen1024 
        ? "isSmallScreen1024" 
        : null;

      // Vérifier que ligne et ligne.ligne_donnee existent et sont valides
      if (!ligne || !ligne.ligne_donnee || ligne.ligne_donnee.length === 0) {
        return null;  // Ne rien rendre si les données sont manquantes ou invalides
      }
    
      const firstCellClass = `${styles.rapport4_table} ${styles.first_cell}`;

      // Filtrer les données en supprimant la dernière colonne uniquement pour les tailles d'écran spécifiques
      let filteredDonnees = ligne.ligne_donnee;
    
      if (screenSize === "isSmallScreen655" || screenSize === "isSmallScreen1024") {
        filteredDonnees = ligne.ligne_donnee.slice(0, -1);  // Supprimer la dernière colonne (Ratios)
      }
    
      if (ligne.ligne_type === 'valeur') {
        // Vérification spécifique pour la ligne "Total famille"
        if (ligne.ligne_donnee[0] === 'Total famille') {
          if(screenSize == "isSmallScreen655"){
            return (
              <>
                <tr className={styles.rapport4_table}>
                <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                  Total famille
                </td>
                </tr>
                <tr className={styles.rapport4_table}>
                  {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[1]}</div> {/* T.T.C. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[3]}</div> {/* H.T./H.S. */}
                      </th>
                      <th className={styles.rapport4_table}>
                        <div>{ligne.ligne_donnee[2]}</div> {/* T.V.A. */}
                        <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* Service */}
                      </th>
                      <th className={styles.rapport4_table}>{ligne.ligne_donnee[5]}</th>
                  </tr>
              </>
          );
        }else{
          return (
            <>
              <tr className={styles.rapport4_table}>
                <td className={firstCellClass} colSpan={isSmallScreen655 ? 3 : ligne.ligne_donnee.length + 1}>
                  Total famille
                </td>
              </tr>

                <tr className={styles.rapport4_table}>
                {!isSmallScreen655 && (
                  <td className={styles.rapport4_table} colSpan={2}>-</td>
                )}
                {/* Protéger le rendu avec un fallback pour les valeurs manquantes */}
                {ligne.ligne_donnee.slice(1).map((donnee, index) => (
                  <td className={styles.rapport4_table} key={index}>
                    {donnee || '0'} {/* Si donnee est null ou undefined */}
                  </td>
                ))}
              </tr>
              </>
            );
        }}
      
        // Pour toutes les autres lignes, vérifier le mode responsive et rendre les données
        if (rapports.cumul) {
          // Si rapports.cumul est vrai
          switch (screenSize) {
            case "isSmallScreen655":
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                    <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  </tr>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                    </th>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                    </th>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                  </tr>
                </>
              );
            case "isSmallScreen1024":
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  {filteredDonnees.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
            
            default:
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
          }
        } else {
          // Si rapports.cumul est faux (ancien switch sans suppression de "Ratios")
          switch (screenSize) {
            case "isSmallScreen655":
          
          
          
              return (
                <>
                  <tr className={styles.rapport4_table}>
                    <td className={firstCellClass}>{filteredDonnees[0] || '0'}</td>  {/* Code */}
                    <td className={firstCellClass} colSpan={2}>{filteredDonnees[1] || '0'}</td>  {/* Désignation */}
                  </tr>
                  <tr className={styles.rapport4_table}>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[2]}</div> {/* T.T.C. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[4]}</div> {/* H.T./H.S. */}
                    </th>
                    <th className={styles.rapport4_table}>
                      <div>{ligne.ligne_donnee[3]}</div> {/* T.V.A. */}
                      <div style={{ borderTop: '1px solid black', marginTop: '5px' }}>{ligne.ligne_donnee[5]}</div> {/* Service */}
                    </th>
                    <th className={styles.rapport4_table}>{ligne.ligne_donnee[6]}</th>
                  </tr>
                </>
              );
            
            
            case "isSmallScreen1024":
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
            
            default:
              return (
                <tr className={styles.rapport4_table}>
                  <td className={firstCellClass}>{ligne.ligne_donnee[0] || '0'}</td>  {/* Code */}
                  <td className={styles.rapport4_table}>{ligne.ligne_donnee[1] || '0'}</td>  {/* Désignation */}
                  {ligne.ligne_donnee.slice(2).map((donnee, index) => (
                    <td className={styles.rapport4_table} key={index}>
                      {donnee || '0'}  {/* Protéger contre les données manquantes */}
                    </td>
                  ))}
                </tr>
              );
          }
        }           
      }
    } else if (rapportfinancier.rapport_id == 2 || rapportfinancier.rapport_id == 3) {

      if(ligne.ligne_type == 'valeur') {

        return ligne.ligne_donnee.map(function(donnee, index) {
          
          if(donnee == 'Total annulations' || donnee == 'Total offerts') {
            return <td id={index} colSpan="4" className={styles.total}>{donnee}</td>
          } else {
            return <td id={index}>{donnee}</td>
          }

        })

      }
      
    } else {

      if(ligne.ligne_type == 'valeur') {

        return (ligne.ligne_donnee.map((donnee, index) =>
          <td id={index}>{donnee}</td>
        ))

      }

    }
  return null;
};


  const renderTitle = () => {
    if (rapports.cumul == true) {
      return <h2><b>{rapportfinancier.rapport_nom}</b> <b>du</b> {cumul.date_min_affichage} <b>au</b> {cumul.date_max_affichage}</h2>  
    } else {
      const date = format(Date.parse(service[0].date_service), 'EEEE dd MMMM yyyy', {locale: fr})
      return <h2><b>{rapportfinancier.rapport_nom}</b> Service n°{service[0].num_service} <b>du</b> {date}</h2>   
    }
  }

  const renderNumtabres = () => {
    if (rapports.cumul == false && rapport.rapport_id == 5) {
      return <h2>BANDE CONTRÔLE N°{service[0].num_tab_res_affichage}</h2>   
    } 
  }

  const renderChargement = () => {
    if(hasMore == true && hasLess == true && pause == false) {
      return (
        <div className={styles.spinner}>
          <Spinner className={styles.spinner} variant="dark" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    } 
  }

  const renderRapportFinancier = () => {
    switch (rapportfinancier.rapport_id) {
      case 4:
        return (
          <div ref={componentRef}>
            <div className={styles.rapport4_titre_section}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres_rapport4}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer_rapport4} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>
  
            {/* Totaux par groupe */}
            <div className={styles.rapport4_table_wrapper}>
              <table className={styles.rapport4_table}>
                <thead>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderHeaderTotaux(ligne, isSmallScreen655)} {/* Header pour Totaux */}
                    </React.Fragment>
                  ))}
                </thead>
                <tbody>
                  {rapportfinancier.sections[0].tableaux[0].lignes.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {renderBodyTotaux(ligne, isSmallScreen655)} {/* Body pour Totaux */}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
  
            {/* Tableaux par Famille */}
            {rapportfinancier.sections.slice(1).map((section) => (
              <div className={styles.section_rapport4} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.rapport4_table_wrapper}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.rapport4_table} key={tableauIndex}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Header pour Famille */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Body pour Famille */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
  
      default:
        // Cas par défaut : afficher un message ou une structure générique si rapport_id n'est pas 4
        return (
          <div ref={componentRef}>
            <div className={styles.grille_titre}>
              <div>{renderTitle()}</div>
              <div className={styles.numtabres}>{renderNumtabres()}</div>
              <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>
                Imprimer
              </Button>
            </div>
  
            {/* Sections et tableaux génériques */}
            {rapportfinancier.sections.map((section) => (
              <div className={styles.section_titre} key={section.section_nom}>
                <h3 className={styles.group_title}>{section.section_nom}</h3>
                <div className={styles.grille_tableau}>
                  {section.tableaux.map((tableau, tableauIndex) => (
                    <div className={styles.tableau} key={tableau.tableau_nom}>
                      <table className={styles.rapport4_table}>
                        <caption>{tableau.tableau_nom}</caption>
                        <thead>
                          {tableau.lignes.map((ligne, index) => (
                            <React.Fragment key={index}>
                              {renderHeaderFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Header générique */}
                            </React.Fragment>
                          ))}
                        </thead>
                        <tbody>
                          {tableau.lignes.map((ligne, index) => (
                            <tr key={index}>
                              {renderBodyFamille(ligne, isSmallScreen655, isSmallScreen1024)} {/* Body générique */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
    }
  };
  
  
  const renderBandeControleList = () => {
    if (rapports.cumul == true) { 
      return (
          <div className={styles.bande} >
          {
            rapportfinancier.sections.map((section, index) => 
              <div key={index} className={styles.document}>
                {
                  section.entete.map((entete) =>
                    <div>{entete}</div>      
                  )
                }
                <div className={styles.document_entete}>
                  {
                    section.commande.map((commande) =>
                    
                      <div className={styles.document_commande}>
                        {commande.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.totaux.map((totaux) =>
                    
                      <div className={styles.document_totaux}>
                        {totaux.map((element) => 
                          <div>{element}</div>
                        )}
                      </div>      
                    )
                  }
                </div>
                <div className={styles.document_entete}>
                  {
                    section.reglement.map((reglement) =>
                    
                      <div className={styles.document_totaux}>
                        {reglement.map((element) => 
                          <div>{element}</div>
                        )}
                      </div> 
                    )
                  }
                </div>
              </div>)
          }  
        </div>
        )
    } else {
      return (
        <div  className={styles.bande}>
        {
          rapportfinancier.sections.map((section) => 
            <div className={styles.document}>
              {
              section.entete.map((entete) =>
                <div>{entete}</div>      
              )
              }
              <div className={styles.document_entete}>
                {
                  section.commande.map((commande) =>
                  
                    <div className={styles.document_commande}>
                      {commande.map((element) => 
                        <div>{element}</div>
                      )}
                    </div>      
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.totaux.map((totaux) =>
                  <div className={styles.document_totaux}>
                    {totaux.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                
                  )
                }
              </div>
              <div className={styles.document_entete}>
                {
                  section.reglement.map((reglement) =>
                  <div className={styles.document_totaux}>
                    {reglement.map((element) => 
                      <div>{element}</div>
                    )}
                  </div>      
                  )
                }
              </div>
            </div>)
        }
      </div>)
    }
  }

  const renderEtatChargement = () => {
    if(hasMore == true && finBandeControle == false && pause == false) 
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionPause}>Pause</Button>
    else if(pause == true && finBandeControle == false)
      return <Button className={styles.bouton_etat} variant="secondary" onClick={fonctionReprise}>Reprise</Button>
  }

  const renderBandeControle = () => {
    return (
      <div ref={componentRef} >
        <div className={styles.grille_titre}>
          <div>{renderTitle()}</div>
          {renderChargement()}
          {renderEtatChargement()}
          <Button className={styles.bouton_imprimer} variant="secondary" onClick={handlePrint}>Imprimer</Button>
        </div>
        {renderBandeControleList()}
      </div>
    )
  }

  const renderRapport = () => {
    if (loading) return <Alert variant='secondary'> Chargement du rapport </Alert>
    if (hasErrors) return <Alert variant='danger'> Impossible de charger le rapport </Alert>
    if (donnee == false) return <Alert variant='warning'> Aucune donnée dans la période sélectionnée </Alert>
    if (loaded == true) {
      if(rapportfinancier.rapport_id == 5) 
        return renderBandeControle()
      else
        return renderRapportFinancier()
    }
  }

  const renderRapportList = () => {
    let side = true
    return rapports.rapports.map((rapport) => <Rapport key={rapport.rapport_id} rapport={rapport} side={side} /> )
  }

  return (
    <div className={styles.rapport_wrapper}>
      <div className={styles.div_rapport_list}>
        <div className={styles.rapport_list}>
          {renderRapportList()}
        </div>
      </div>
      <div className={styles.div_rapport}>
        {renderRapport()}
      </div>
    </div>
  )
}